import React from 'react'
import ArrowRight from '../images/icons/arrow-right.svg'
import AutomationLetter from '../images/icons/automation.svg'
import '../css/intelligent-automation.scss'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import Insight from './home/insight/insight'
import { Link } from 'gatsby'

const IntelligentAutomation = () => {
    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="intelligent-automation pt-5">
                <div className="position-relative">
                    {/* hero section */}
                    <div className="bg-intelligent py-5">
                        <div className="container py-3 my-4">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-1 px-0 mt-4">
                                            <div style={{ borderBottom: '3px solid #ffffff' }}></div>
                                        </div>
                                        <div className="col-md-8">
                                            <h1 className="text-white font-weight-bold" style={{ lineHeight: '58px', fontSize: '2.8rem' }}>Intelligent Automation</h1>

                                            <p className="text-white mb-0" style={{ lineHeight: '28px', fontSize: '1.3rem' }}>Extend your business horizons with the amazing power of intelligent automation technologies.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 my-auto">
                                    <p className="off-white mb-0" style={{ lineHeight: '26px', fontSize: '1.06rem' }}>Let us work with you to automate your end-to-end business operations, fusing intelligent automation technologies (AI and RPA) with human capabilities, to boost process efficiency, foster rapid resolution and improve customer experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="automation-letter-div mb-5">
                        <img src={AutomationLetter} alt="" className="automation-img" />
                    </div>
                </div>

                <div className="my-5 py-5"></div>
                <div className="my-md-5 py-md-5"></div>


                <div className="container my-5 py-5">
                    <div className="bg-robotics py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Robotic Process Automation (RPA)</h4>

                                                <p className="text-secondary"  style={{lineHeight: '26px'}}>We will save you and your workforce the stress of carrying out repetitive, routine and manual tasks, by configuring softwares to interact well with your digital systems and stand in the stead of humans to execute your business processes. </p>

                                                <p className="text-secondary mb-1"  style={{lineHeight: '26px'}}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Equip your workforce to handle tasks exceptionally.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Gather insights faster and make better, timebound data-driven decisions. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Automate all front and back office business processes.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Train your machines to serve better.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Coordinate work across combined human-bot teams.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Promote effective monitoring to detect fraud.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'intelligent' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/intelligent-automation-usecase"><p className="cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container my-5 py-5">
                    <div className="bg-ai py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Artificial Intelligence (AI) and Machine Learning (ML)</h4>

                                                <p className="text-secondary"  style={{lineHeight: '26px'}}>We will help your business operate smarter with intelligence beyond human capacity, so that your processes and delivery are faster and more effective even while remaining error-free.</p>

                                                <p className="text-secondary mb-1"  style={{lineHeight: '26px'}}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0"  style={{lineHeight: '26px'}}>Automate tasks for speedy delivery.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Achieve 100% accuracy in all your business deliveries.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Apply intelligence to solve any problem.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Save your business time and money. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Alleviate the workload of your human workforce. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Optimize monitoring and fraud detection.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'intelligent' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/intelligent-automation-usecase"><p className="cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="my-md-5 my-0 py-md-5 py-0"></div>
            <Insight />
        </Layout>
    )
}

export default IntelligentAutomation
